<template>
  <div>
    <baidu-map-view ref="baiduMap" :map-data="mapData" />
    <div style="font-size: 20px; padding: 10px 16px">
      夜间中队店铺二维码设置
    </div>
    <van-field label="店铺名称" :value="shop_Name" readonly />
    <van-button v-if="!isBind" color="rgb(44, 167, 234)" block @click="addBox">
      设置当前夜间中队二维码
    </van-button>
    <van-button v-if="isBind" color="#ccc" block @click="delBox">
      取消当前夜间中队二维码
    </van-button>
  </div>
</template>

<script>
  import store from '@/store'
  import Base64 from '@/util/Base64'
  import { Toast, Dialog } from 'vant'
  import BaiduMapView from '@/components/BaiduMapView.vue'
  import {
    ZDBox_BD,
    Add_ZDBox,
    Del_ZDBox,
  } from '@/api/workBench/attendance/attendance'
  import {
    getGeolocation,
    startGeolocation,
    stopGeolocation,
  } from '@/util/jsApi'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'ShopQR',
    components: { BaiduMapView },
    data() {
      return {
        street_ID: null,
        street_Name: '',
        shop_ID: null,
        shop_Name: '当前地址暂时未绑定店铺',
        mapData: {
          data: [
            { lng: 121.161162, lat: 30.043746 },
            { lng: 0, lat: 0 },
          ],
          mapType: 3,
          showButton: false,
          height: '300px',
          preRender: true,
        },
        cell_ID: null,
        cell_Name: '',
        person_ID: null,
        team_ID: null,
        isBind: false,
      }
    },
    created() {
      this.street_ID = this.$route.query.street_ID
      this.shop_ID = Base64.decode(this.$route.query.shopID)
      this.shop_Name = Base64.decode(this.$route.query.shopName)
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      let teamInfo = JSON.parse(store.getters['user/teamInfo'])
      this.cell_ID = parseInt(teamInfo.Cell_ID)
      this.person_ID = parseInt(userInfo.User_ID)
      this.team_ID = parseInt(teamInfo.Team_ID)
      this.initLocation()
      this.setShopInfo()
      this.startGeolocation()
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'teamQR',
        '城管-夜间重点店铺设置',
        'zzdcg.yy.gov.cn/teamQR'
      )
    },
    destroyed() {
      stopGeolocation('shopQR')
    },
    methods: {
      //初始获取当前位置坐标
      initLocation() {
        let that = this
        getGeolocation(function(res) {
          let point = that.$refs['baiduMap'].Convert_GCJ02_To_BD09({
            lng: res.longitude,
            lat: res.latitude,
          })
          that.mapData.data[0].lat = point.lat
          that.mapData.data[0].lng = point.lng
          that.$refs['baiduMap'].render()
        })
      },

      //开始实时获取定位点
      startGeolocation() {
        let that = this
        startGeolocation('shopQR', function(res) {
          let point = that.$refs['baiduMap'].Convert_GCJ02_To_BD09({
            lng: res.longitude,
            lat: res.latitude,
          })
          that.mapData.data[0].lat = point.lat
          that.mapData.data[0].lng = point.lng
          that.$refs['baiduMap'].render()
          Toast('实时定位成功')
        })
      },

      //设置坐标点
      async setShopInfo() {
        const { data } = await ZDBox_BD({ Street_ID: this.street_ID })
        if (data.Code === '1') {
          this.isBind = true
          this.mapData.data[1].lat = parseFloat(data.dplat)
          this.mapData.data[1].lng = parseFloat(data.dplon)
        } else {
          this.isBind = false
        }
        this.$refs['baiduMap'].render()
      },

      //设置重点中队
      async addBox() {
        Dialog.confirm({
          message: '您确定要将当前地址二维码设置为夜间中队店铺二维码吗？',
        })
          .then(async () => {
            Toast.loading({
              duration: 3000, // 持续展示 toast
              message: '正在操作中...',
              forbidClick: true,
            })
            const { data } = await Add_ZDBox({
              Street_ID: this.street_ID,
              Person_ID: this.person_ID,
              Coordinate:
                this.mapData.data[0].lng + ',' + this.mapData.data[0].lat,
              Team_ID: this.team_ID,
            })
            Toast.clear()
            if (data.MSG === '1') {
              Toast('操作成功')
              await this.setShopInfo()
              this.isBind = true
            } else {
              Toast('操作失败')
            }
          })
          .catch(() => {
            // on cancel
          })
      },

      //删除重点中队
      async delBox() {
        Dialog.confirm({
          message: '确定要取消当前夜间中队二维码地址的设置吗？',
        })
          .then(async () => {
            Toast.loading({
              duration: 3000, // 持续展示 toast
              message: '正在操作中...',
              forbidClick: true,
            })
            const { data } = await Del_ZDBox({ Street_ID: this.street_ID })
            Toast.clear()
            Toast(data.MSG === '1' ? '操作成功' : '操作失败')
            if (data.MSG === '1') {
              this.isBind = false
              this.mapData.data[1].lat = 0
              this.mapData.data[1].lng = 0
              await this.setShopInfo()
            }
          })
          .catch(() => {
            // on cancel
          })
      },
    },
  }
</script>

<style scoped></style>
